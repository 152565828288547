import { Component, Inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterState } from '@angular/router';
import { ILoggedOnUser } from './_interface/portal/loggedUser.interface';
import { PortalAuthenticationService } from './_services/dx-portal-auth.service';
import { IdxAdjacentItems } from './_shared/dx-components/_interfaces/icontrols-common/imenubar.interface';
import { IdxMenuItem } from './_shared/dx-components/_interfaces/iprimeng/irefobject.interface';
import { DxToastService } from './_services/dx-notifications.service';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  appMenuItems!: IdxMenuItem[];
  rightMenuBarItems!: IdxAdjacentItems[];
  title = 'DemandXchange';
  loggedOnUser!: ILoggedOnUser | null;
  private window: any;
  constructor(public authSvc: PortalAuthenticationService,private titleService: Title, public activeRoute: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
      private router: Router, private notifSvc: DxToastService)
  {
    this.window = authSvc.nativeWindow;
    this.handleRouteEvents();
    this.authSvc.loggedOnUser.subscribe(user => this.loggedOnUser = user);
    //Remove logout which redirect to login
  }
  menuItems:any[] = [];
  ngOnInit() {
    this.authSvc.loggedOnUser.subscribe(user => {
       if(user){
        this.rightMenuBarItems = [
          // {
          //    id: "portal-user-avatar",
          //    controlType: "avatar",
          //    config: {},
          // },
          // {
          //     id: "portal-search",
          //     controlType: "search",
          //     config: {}
          // },
          {
            id: "portal-button-logout",
            controlType: "button",
            config: {
                visible: true,
                onClick: this.logout.bind(this),
                icon: "pi pi-power-off",
                text:  "Logout"
            }
          },
        ]
        this.authSvc.loadMainMenus();
        this.authSvc.menuItems.subscribe(itemsMenu => {
          this.menuItems = itemsMenu
          this.appMenuItems  = [
            {
                label:'DemandXchange',
                icon:'pi pi-fw pi-list',
                items: itemsMenu,
            }
          ];
        });
        this.appMenuItems  = [
          {
              label:'DemandXchange',
              icon:'pi pi-fw pi-list',
              items: this.menuItems,
          }
        ];
       }
    });
  }
  
  routeToApp(app: string){
    this.router.navigate([app])
  }
  logout(){
     this.authSvc.logout();
     this.router.navigate(['/login']);
  }
  isSSOLogin: boolean = false;
  handleRouteEvents() {
    this.router.events.subscribe(event => {
      if(event instanceof NavigationStart){
         if(event.url.indexOf("content-hub-sso-login")){
            this.isSSOLogin = true;
         }else{
            if(!this.loggedOnUser){
              this.logout();
            }
         }
      }
      if (event instanceof NavigationEnd) {
        // this.gtmService.addGtmToDom();
        const title = this.getTitle(this.router.routerState, this.router.routerState.root).join('-');
        // const gtmTag = {
        //    event: "pageView",
        //    "Page Hostname": title,
        //    "page_hostname": title,
        //    pageName: title,
        //    page_title: title,
        //    page_path: event.urlAfterRedirects,
        //    page_location: this.document.location.href
        // }
        // this.window.dataLayer.push(gtmTag);
        // this.gtmService.pushTag(gtmTag);
        this.titleService.setTitle(title);
        gtag('event', 'page_view', {
          page_title: title,
          page_path: event.urlAfterRedirects,
          page_location: this.document.location.href
        })
      }
    });
  }
  getTitle(state: RouterState, parent: ActivatedRoute): string[] {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data['title']) {
      data.push(parent.snapshot.data['title']);
    }
    if (state && parent && parent.firstChild) {
      data.push(...this.getTitle(state, parent.firstChild));
    }
    return data;
  }
  
}
